import React, { PureComponent } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { Typography } from '@material-ui/core'
import { Document, Page } from 'react-pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { WindowLocation } from '@reach/router'

import Layout from '../components/layout'
import theme from '../theme'
import Modal from '../components/modal'
import ContactInfo from '../components/contactInfo'
import Section, {Header, SubHeader, Divider} from '../components/section/section'

// Image imports
import AboutImage from '../images/about/about.jpg'
import MapWalking from '../images/about/map-arrows.png'
import StarImage from '../images/icons/star-boxed.svg'
import Brochure from '../documents/the-venue-of-hollywood-brochure.pdf'

const ImageWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-height: calc(80vw * 9/16);
    overflow: hidden;

    > img {
        margin: 0;
    }

    @media(max-width: 768px) {
        max-height: calc(90vw * 9/16);
    }
`

const MapImage = styled.img`
    max-width: calc(70vw * 4/6);
    object-fit: contain;
    display: block;
    margin: 40px 0 40px 20px;
    padding: 10px;
    background: rgba(0,0,0,0.1);
    border-radius: 3px;
    box-shadow: 0px 2px 8px -4px black;

    :hover {
        cursor: pointer;
        background: rgba(0,0,0,0.1);
        border-radius: 3px;
    }

    @media(max-width: 880px) {
        max-width: 100%;
        max-height: initial;
    }
`

const IconWrapper = styled.div<{shouldDisable: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255, 0.8);
    width: 75px;
    cursor: pointer;
    pointer-events: ${props => props.shouldDisable ? 'none' : 'auto'};
    opacity: ${props => props.shouldDisable ? '0.5' : '1.0'};
`

const PreviousPage = ({ handleClick, shouldDisable } : { handleClick: () => void, shouldDisable: boolean }) => (
    <IconWrapper
        onClick={handleClick}
        shouldDisable={shouldDisable}>
        <FontAwesomeIcon icon={faArrowLeft} />
    </IconWrapper>
)

const NextPage = ({ handleClick, shouldDisable } : { handleClick: () => void, shouldDisable: boolean }) => (
    <IconWrapper
        onClick={handleClick}
        shouldDisable={shouldDisable}>
        <FontAwesomeIcon icon={faArrowRight} />
    </IconWrapper>
)

type Props = { 
    location: WindowLocation
}

type State = {
    isMapToggled: boolean,
    currentPage: number,
    numberOfPages: number
}

export default class About extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)

        this.toggleFloorPlan = this.toggleFloorPlan.bind(this)
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
        this.handlePreviousPage = this.handlePreviousPage.bind(this)
        this.handleNextPage = this.handleNextPage.bind(this)
        this.downloadBrochure = this.downloadBrochure.bind(this)

        this.state = {
            isMapToggled: false,
            currentPage: 1,
            numberOfPages: 0
        }
    }

    toggleFloorPlan() {
        this.setState({
            isMapToggled: !this.state.isMapToggled
        })
    }

    onDocumentLoadSuccess({ numPages } : { numPages: number }) {
        this.setState({
            currentPage: 1,
            numberOfPages: numPages
        })
    }

    handlePreviousPage() : void {
        this.setState({
            currentPage: this.state.currentPage !== 0 ? this.state.currentPage - 1 : 0
        })
    }

    handleNextPage() : void {
        this.setState({
            currentPage: this.state.currentPage !== this.state.numberOfPages ? this.state.currentPage + 1 : this.state.numberOfPages
        })
    }

    downloadBrochure() {
        axios({
            url: Brochure,
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'the-venue-of-hollywood-brochure.pdf')
            link.click()
            window.URL.revokeObjectURL(url)
          })
    }

    render() {
        const modalizedMap = this.state.isMapToggled ? (
            <Modal handleClose={this.toggleFloorPlan}>
                <img src={MapWalking} alt="Map" style={{maxHeight: '90vh', padding: '40px'}} />
            </Modal>
        ) : null

        const PDFDocument = typeof window !== 'undefined' && window.innerWidth > 768 ? (
            <Section
                margin="40px auto"
                boxShadow="0px 2px 10px -3px black"
                borderRadius="2px">
                <PreviousPage handleClick={this.handlePreviousPage} shouldDisable={this.state.currentPage === 1} />
                <Document 
                    file={Brochure}
                    onLoadSuccess={this.onDocumentLoadSuccess}>
                    <Page pageNumber={this.state.currentPage} />
                </Document>
                <NextPage handleClick={this.handleNextPage} shouldDisable={this.state.currentPage === this.state.numberOfPages} />
            </Section>
        ) : null

        return(
            <Layout location={this.props.location}>
                <Section width="100%" margin="0" flexDirection="column">
                    <ImageWrapper>
                        <img src={AboutImage} alt="the venue of hollywood entrance"/>
                    </ImageWrapper>
                    <Section 
                        id="venue"
                        flexDirection="column" 
                        margin="0"
                        padding="40px 0" >
                        <Header title="The Venue of Hollywood" />
                        <SubHeader maxWidth="60%">
                            Located in the heart of Hollywood, at the
                            prestigious Hollywood & Highland Center,
                            the prime location of The Venue of Hollywood
                            offers a unique and versatile event space for
                            the A-list clientele of Hollywood, allowing the
                            hosting of a multitude of events in a sleek and
                            refined environment. Coupled with the
                            state-of-the-art LED screen technology that can
                            be used in a variety of ways to make any event
                            more impactful, The Venue of Hollywood is a
                            top-tier space, perfect for your next event.
                        </SubHeader>
                        <Divider themeColor={theme.palette.primary.main} />
                    </Section>
                    <Section 
                        id="map"
                        width="100%"
                        flexDirection="column"
                        margin="0 auto" 
                        padding="0 20px"
                        background={theme.palette.primary.main}>
                        <Section 
                            maxWidth="80vw"
                            width="auto"
                            flexDirection="column"
                            margin="40px auto">
                            <MapImage 
                                id="building-map" 
                                src={MapWalking} 
                                onClick={this.toggleFloorPlan} />
                            <Typography 
                                variant="h6"
                                gutter={true}
                                style={{
                                    color: 'white'
                                }}>
                                Legend
                            </Typography>
                            <ul style={{margin: '20px 20px 20px 40px'}}>
                                <li style={{listStyleImage: `url(${StarImage})`, fill: 'white', marginLeft: '20px', color: 'white'}}>Elevators</li>
                            </ul>
                            <Typography 
                                variant="h6"
                                gutter={true}
                                style={{
                                    color: 'white'
                                }}>
                                Contact
                            </Typography>
                            <ContactInfo margin="0" color="white"/>
                        </Section>
                    </Section>
                    {modalizedMap}
                    <Section
                        margin="0"
                        padding="40px 0"
                        flexDirection="column"
                        width="100%"
                        background="whitesmoke">
                        <Section 
                            id="brochure"
                            flexDirection="column" 
                            margin="0">
                            <Header title="Brochure"/>
                            <Divider themeColor={theme.palette.primary.main}/>
                        </Section>
                        {PDFDocument}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <FontAwesomeIcon icon={faFileDownload}/>
                            <p style={{margin: '0 0 0 20px', cursor: 'pointer', color: theme.palette.primary.light}} onClick={this.downloadBrochure}>Download</p>
                        </div>
                    </Section>
                </Section>
            </Layout>
        )
    }
}